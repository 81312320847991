e4.header = {
    init: function () {
        // console.log( 'e4.header.autoHideTolerance', e4.settings.header.autoHideTolerance.down );
        var jHeader = jQuery( '.js-e-header' );
        if ( jHeader.length === 1 ) {
            // If the page has sticky local horizontal navigation, the header cannot be sticky.
            if ( jQuery( '.e-nav-local-horizontal.is-sticky:visible' ).length > 0 ) {
                e4.header.disableSticky();
            }
            e4.header.lastScrollTop          = 0;
            e4.header.scrollDirectionCurrent = 'down';
            e4.header.canAutoHide            = jHeader.data( 'autohide' );
            e4.header.isTransitioning        = false;
            e4.header.isShown                = true;
            e4.header.event                  = e4.util.registerEvents( 'hide hidden show shown', 'header' );
            e4.header.bindEvents();
        }
    },
    bindEvents: function () {
        var jWindow     = jQuery( window );
        e4.header.isRAF = false;
        // jWindow.on( 'resize', function () {
        //     if ( e4.header.isRAF === false ) {
        //         window.requestAnimationFrame( function () {
        //             e4.header.isRAF = false;
        //         } );
        //     }
        //     e4.header.isRAF = true;
        // } );
        jWindow.on( 'scroll', function () {
            // console.log( 'e4.header.isRAF', e4.settings.header.autoHideTolerance[ e4.header.scrollDirectionCurrent
            // ], e4.header.scrollDirectionCurrent );
            if ( e4.header.isRAF === false ) {
                window.requestAnimationFrame( function () {
                    e4.header.toggleSticky();
                    e4.header.toggleVisibility();
                    e4.header.isRAF = false;
                } );
            }
            // else {
            //     console.warn( 'header is busy requestAnimationFrame' );
            // }
            e4.header.isRAF = true;
        } );
    },
    toggleSticky: function () {
        if ( jQuery( window ).scrollTop() > 0 ) {
            jQuery( '.js-e-header' ).addClass( 'is-stuck' );
        }
        else {
            jQuery( '.js-e-header' ).removeClass( 'is-stuck' );
        }
    },
    toggleVisibility: function () {
        if ( e4.header.canAutoHide === true ) {
            var windowScrollTop              = jQuery( window ).scrollTop();
            e4.header.scrollDirectionCurrent = windowScrollTop > e4.header.lastScrollTop ? 'down' : 'up';
            // Check for iOS scroll bounce
            if ( windowScrollTop <= 0 ) {
                e4.header.show();
            }
            else if ( Math.abs( windowScrollTop - e4.header.lastScrollTop ) > e4.settings.header.autoHideTolerance[ e4.header.scrollDirectionCurrent ] ) {
                if ( e4.header.scrollDirectionCurrent === 'down' && e4.header.scrollDirectionLast !== 'down' ) {
                    if ( jQuery( '.js-e-header' ).find( '.dropdown.show, .modal.show' ).length < 1 ) {
                        e4.header.hide();
                    }
                }
                else if ( e4.header.scrollDirectionCurrent === 'up' && e4.header.scrollDirectionLast !== 'up' ) {
                    e4.header.show();
                }
                e4.header.lastScrollTop       = windowScrollTop;
                e4.header.scrollDirectionLast = e4.header.scrollDirectionCurrent;
            }
        }
    },
    enableSticky: function () {
        jQuery( '.js-e-header' ).addClass( 'is-sticky' );
    },
    disableSticky: function () {
        jQuery( '.js-e-header' ).removeClass( 'is-sticky' );
    },
    show: function () {
        if ( e4.header.isTransitioning === false && e4.header.isShown === false ) {
            var jHeader = jQuery( '.js-e-header' );
            jHeader.trigger( e4.header.event.show );
            if ( e4.settings.event.transitionend !== false ) {
                e4.header.isTransitioning = true;
                jHeader.one( e4.settings.event.transitionend, function () {
                    e4.header.isTransitioning = false;
                    jHeader.trigger( e4.header.event.shown );
                } );
            }
            else {
                jHeader.trigger( e4.header.event.shown );
            }
            jHeader.removeClass( 'is-hidden' );
            e4.header.isShown = true;
        }
        // else {
        //     console.log( 'show condition not met', e4.header.isTransitioning === false, e4.header.isShown === false
        // ); }
    },
    hide: function () {
        if ( ( e4.header.isTransitioning === false && e4.header.isShown === true ) && e4.header.canAutoHide === true ) {
            var jHeader = jQuery( '.js-e-header' );
            jHeader.trigger( e4.header.event.hide );
            if ( e4.settings.event.transitionend !== false ) {
                e4.header.isTransitioning = true;
                jHeader.one( e4.settings.event.transitionend, function () {
                    e4.header.isTransitioning = false;
                    jHeader.trigger( e4.header.event.hidden );
                } );
            }
            else {
                jHeader.trigger( e4.header.event.hidden );
            }
            jHeader.addClass( 'is-hidden' );
            e4.header.isShown = false;
        }
        // else {
        //     console.log( 'wont hide header', e4.header.isTransitioning, e4.header.isShown, e4.header.canAutoHide );
        // }
    }
};

