e4.checkout = {
    debug: true,
    init: function () {
        if ( jQuery( '.js-e-checkout' ).length === 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerHelpers( 'compare counter' );
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-checkout #js-e-handlebars-tmpl-cart #js-e-handlebars-tmpl-checkout-summery' );
                e4.handlebars.registerPartials( '#js-e-handlebars-partial-gls #js-e-handlebars-partial-pacsoft' );
                e4.checkout.config();
                e4.checkout.bindEvents();
            } );
        }
        if ( jQuery( '.js-e-checkout-receipt' ).length === 1 ) {
            e4.checkout.steps.receipt.init();
        }
    },
    config: function () {
        var disabledSteps = jQuery( '.js-e-checkout-steps-deactivated' ).val().split( ',' );
        for ( var i = 0; i < disabledSteps.length; i++ ) {
            if ( e4.checkout.steps.active[ disabledSteps[ i ] ] !== undefined ) {
                e4.checkout.steps.active[ disabledSteps[ i ] ] = false;
            }
        }
        jQuery.each( e4.checkout.steps.active, function ( index, isActive ) {
            if ( isActive === true ) {
                e4.checkout.steps.first = index;
                return false;
            }
            e4.checkout.steps.first = 'approve';
        } );
    },
    bindEvents: function () {
        var savedStep = sessionStorage.checkoutStep !== undefined ? JSON.parse( sessionStorage.checkoutStep ) : null;
        if ( savedStep !== null ) {
            e4.checkout.steps.map = savedStep.map;
        }
        jQuery( window ).on( 'hashchange', function () {
            var hashStep = e4.util.hash.get( 'step' );
            if ( hashStep !== undefined ) {
                e4.checkout.order.get( hashStep, e4.settings.ecom.checkout.delay );
            }
        } );

        if ( e4.util.hash.get( 'step' ) === undefined ) {
            if ( savedStep !== null ) {
                if ( savedStep.index <= 1 ) {
                    e4.util.hash.replace( 'step', e4.checkout.steps.first );
                }
                else {
                    e4.util.hash.replace( 'step', savedStep.name );
                }
            }
            else {
                e4.util.hash.replace( 'step', e4.checkout.steps.first );

            }

        }
        else {
            jQuery( window ).triggerHandler( 'hashchange' );
        }
        jQuery( '.js-e-checkout' ).on( 'hidden.bs.collapse', '.collapse', function () {
            if ( e4.checkout.steps.current !== e4.checkout.steps.target ) {
                if ( Math.max( jQuery( window ).width(), window.innerWidth ) > e4.settings.breakpoint[ e4.settings.ecom.checkout.multipageBreakpoint ] ) {
                    e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( '.js-e-checkout-step[data-step="' + e4.checkout.steps.target + '"]:first' ) - jQuery( '.js-e-header' ).height() , e4.header.hide );
                }
            }
            e4.checkout.steps.current = e4.checkout.steps.target;
        } ).on( 'hide.bs.collapse', '.collapse', function () {
            if ( e4.checkout.steps.current !== e4.checkout.steps.target ) {
                if ( Math.max( jQuery( window ).width(), window.innerWidth ) <= e4.settings.breakpoint[ e4.settings.ecom.checkout.multipageBreakpoint ] ) {
                    jQuery( window ).scrollTop( 0 );
                }
            }
        } ).on( 'show.bs.collapse', '.collapse', function () {
            if ( e4.checkout.steps.direction !== null ) {
                jQuery( '.js-e-checkout' ).addClass( 'e-checkout-direction-' + e4.checkout.steps.direction );
            }
        } ).on( 'shown.bs.collapse', '.collapse', function ( event ) {
            jQuery( event.currentTarget ).find( 'form' ).validate();
        } ).on( 'change', '.js-e-checkout-toggle-field', function ( event ) {
            event.preventDefault();
            var jToggleField = jQuery( this ).val(),
                jTarget = jQuery( '.js-e-checkout-toggle-target[data-target="' + jToggleField + '"]' );
            jTarget.slideToggle( e4.settings.animation.speed );
            if ( jQuery( this ).is( ':checked' ) === false ) {
                jTarget.find( 'input, textarea' ).val( '' );
            }
        } ).on('change', 'select[name="EcomOrderDeliveryCountry"]', function(){
            e4.checkout.order.countryChange();
        });

        e4.checkout.steps.init();
        e4.checkout.cart.init();
        e4.checkout.order.init();
    },
    cart: {
        init: function () {
            e4.checkout.cart.get();
            e4.checkout.cart.bindEvents();
        },
        bindEvents: function () {
            jQuery( '.js-e-checkout' ).on( 'change', '.js-e-checkout-update-cart', function () {
                e4.checkout.cart.get();
            } ).on( 'click', '.js-e-checkout-update-cart-btn', function () {
                e4.checkout.cart.get();
            } );
        },
        get: function () {
            jQuery.ajax( {
                    url: e4.settings.ecom.cart.url,
                    type: 'POST',
                    beforeSend: function () {
                        jQuery( '.js-e-checkout-cart-loading-overlay' ).addClass( 'is-loading' );
                    },
                    data: jQuery( '.js-e-checkout-step-form' ).serialize()
                } )
                .done( function ( data ) {
                    e4.checkout.cart.build( data );
                    if ( e4.checkout.debug === true ) {
                        // console.group( '%c Cart data', 'color: #07c;font-size: 12px;' );
                        // console.table( data.cart );
                        // console.groupEnd();
                    }

                } )
                .fail( function ( error ) {
                    if ( e4.checkout.debug === true ) {
                        // console.error( 'Failed to fetch CART DATA, please check your settings!.' );
                        // console.error( error.responseText );
                    }
                } );
        },
        build: function ( data ) {
            setTimeout( function () {
                jQuery( '#js-e-checkout-cart' ).html( e4.handlebars.tmpl.cart( data ) );
                jQuery( '#js-e-checkout-cart-modal' ).html( e4.handlebars.tmpl.cart( data ) );
                e4.loading.manualOverride( '#js-e-checkout-cart' );
            }, e4.settings.ecom.checkout.delay );

        }
    },
    order: {
        hasChanged: {
            payment: false,
            shipping: false
        },
        init: function () {
            e4.checkout.order.bindEvents();
        },
        bindEvents: function () {
            jQuery( '.js-e-checkout' ).on( 'change', '.js-e-checkout-update-order', function () {
                var jStep = jQuery( this ).closest( '.js-e-checkout-step' ).data( 'step' );
                if ( jStep !== e4.util.hash.get( 'step' ) ) {
                    e4.util.hash.set( 'step', jStep );
                }
                else {
                    jQuery( window ).triggerHandler( 'hashchange' );
                }
            } );
        },
        countryChange: function(){
            e4.checkout.steps.shipping.gls.clear();
            e4.checkout.steps.shipping.pacsoft.clear();
        },
        isPreviosStepsCompleted: function ( data ) {
            if ( data.steps.states.current === 'customer' ) {
                if ( data.steps.shipping.isHidden === false && data.steps.shipping.isCompleted === false ) {
                    return e4.util.hash.replace( 'step', 'shipping' );
                }
            }
            else if ( data.steps.states.current === 'payment' ) {
                if ( data.steps.shipping.isHidden === false && data.steps.shipping.isCompleted === false ) {
                    return e4.util.hash.replace( 'step', 'shipping' );
                }
                else if ( data.steps.customer.isHidden === false && data.steps.customer.isCompleted === false ) {
                    return e4.util.hash.replace( 'step', 'customer' );
                }
            }
            else if ( data.steps.states.current === 'approve' ) {
                if ( data.steps.shipping.isHidden === false && data.steps.shipping.isCompleted === false ) {
                    return e4.util.hash.replace( 'step', 'shipping' );
                }
                else if ( data.steps.customer.isHidden === false && data.steps.customer.isCompleted === false ) {
                    return e4.util.hash.replace( 'step', 'customer' );
                }
                else if ( data.steps.payment.isHidden === false && data.steps.payment.isCompleted === false ) {
                    return e4.util.hash.replace( 'step', 'payment' );
                }
            }
            return true;
        },
        get: function ( step, delay ) {
            e4.checkout.steps.target = step;
            jQuery( '.js-e-checkout-step-previous' ).val( e4.checkout.steps.current );
            jQuery( '.js-e-checkout-step-current' ).val( e4.checkout.steps.target );
            jQuery( '.js-e-checkout-step-index' ).val( e4.checkout.steps.map[ e4.checkout.steps.target ] );
            jQuery.ajax( {
                    url: e4.settings.ecom.checkout.url,
                    type: 'POST',
                    beforeSend: function () {
                        jQuery( '.js-e-checkout-step-loading-overlay' ).addClass( 'is-loading' );
                    },
                    data: jQuery( '.js-e-checkout-step-form' ).serialize()
                } )
                .done( function ( data ) {
                    if ( e4.checkout.order.isPreviosStepsCompleted( data ) === true ) {
                        e4.checkout.order.dataHandler( data, e4.checkout.steps.target, delay );
                    }

                    if ( e4.checkout.debug === true ) {
                        // console.group( '%c Order data', 'color: #07c;font-size: 12px;' );
                        // console.table( data.order );
                        // console.groupEnd();
                        // console.group( '%c Steps data', 'color: #07c;font-size: 12px;' );
                        // console.table( data.steps );
                        // console.groupEnd();
                    }

                } )
                .fail( function ( error ) {
                    if ( e4.checkout.debug === true ) {
                        // console.error( 'Failed to fetch ORDER DATA, please check your settings!' );
                        // console.error( error.responseText );
                    }

                } );
        },
        dataHandler: function ( data, step, delay ) {
            var glsShippingID = jQuery( '.js-e-checkout-shipping-id-gls' ).val(),
                pacsoftShippingID = jQuery( '.js-e-checkout-shipping-id-pacsoft' ).val();

            if ( data.steps.shipping.hasChanged === true ) {
                e4.checkout.order.hasChanged.shipping = data.steps.shipping.hasChanged;
                data.steps.shipping.hasChanged = 'e-checkout-step-has-changed';
            }
            if ( data.steps.payment.hasChanged === true ) {
                e4.checkout.order.hasChanged.payment = data.steps.payment.hasChanged;
                data.steps.payment.hasChanged = 'e-checkout-step-has-changed';
            }
            if ( data.steps.shipping.shippingmethodID === glsShippingID ) {
                data.steps.shipping.isGLS = true;
                data.steps.shipping.isPacsoft = false;
                data.steps.shipping.isStandard = false;
                data.steps.customer.alternativeBillingAddress = true;
            }
            else if ( data.steps.shipping.shippingmethodID === pacsoftShippingID ) {
                data.steps.shipping.isPacsoft = true;
                data.steps.shipping.isStandard = false;
                data.steps.shipping.isGLS = false;
                data.steps.customer.alternativeBillingAddress = true;
            }
            else {
                data.steps.shipping.isStandard = true;
                data.steps.shipping.isPacsoft = false;
                data.steps.shipping.isGLS = false;
            }
            if ( data.steps.index <= 1 ) {
                for ( var i = 0; i < data.steps.progress.length; i++ ) {
                    e4.checkout.steps.map[ data.steps.progress[ i ].name ] = data.steps.progress[ i ].index;
                }
            }

            sessionStorage.checkoutStep = JSON.stringify( {
                name: data.steps.name,
                index: data.steps.index,
                map: e4.checkout.steps.map
            } );

            e4.checkout.order.build( data, step, delay );
        },
        build: function ( data, step, delay ) {
            setTimeout( function () {
                jQuery( '#js-e-checkout-form' ).html( e4.handlebars.tmpl.checkout( data ) );
                jQuery( '#js-e-checkout-summery-modal' ).html( e4.handlebars.tmpl.checkoutSummery( data ) );
                e4.checkout.steps.handler.switch();
                e4.checkout.tracking.step( data.steps[ e4.checkout.steps.current ] );
                e4.loading.manualOverride( '#js-e-checkout-form' );
            }, delay );
        }

    },
    steps: {
        first: 'shipping',
        current: 'shipping',
        active: {
            shipping: true,
            customer: true,
            payment: true,
        },
        target: null,
        map: {},
        direction: null,
        init: function () {
            e4.checkout.steps.handler.init();
            e4.checkout.steps.shipping.init();
            e4.checkout.steps.customer.init();
            e4.checkout.steps.payment.init();
            e4.checkout.steps.approve.init();

        },
        addressMapper: function ( jParent, mappingObj ) {
            jQuery.each( mappingObj, function ( key, value ) {
                jParent.find( 'input[name="' + key + '"]' ).val( value );
            } );
        },
        handler: {
            init: function () {
                e4.checkout.steps.handler.next();
                e4.checkout.steps.handler.edit();
                e4.checkout.steps.handler.companyAddress();
            },
            switch: function () {
                e4.checkout.steps.handler.methodChange( e4.checkout.steps.current );
                var jCollapse = jQuery( '#js-e-checkout-accordion' );
                jCollapse.find( '.collapse.js-e-checkout-collapse-hide' ).collapse( 'hide' );
                jCollapse.find( '.collapse.js-e-checkout-collapse-show' ).collapse( 'show' );

                if ( e4.checkout.steps.map[ e4.checkout.steps.target ] === undefined ) {
                    e4.util.hash.replace( 'step', e4.checkout.steps.first );
                }
                else {
                    if ( e4.checkout.steps[ e4.checkout.steps.target ].show !== undefined ) {
                        e4.checkout.steps[ e4.checkout.steps.target ].show();
                    }
                }
                jQuery( 'body' ).attr( 'class', 'e-checkout-page e-checkout-page-' + e4.checkout.steps.target );
            },
            next: function () {
                jQuery( '.js-e-checkout' ).on( 'click', '.js-e-checkout-change-step', function ( event ) {
                    event.preventDefault();
                    var jStep = jQuery( this ).data( 'target' ).replace( '#', '' ),
                        jForm = jQuery( this ).closest( 'form' );
                    if ( jForm.valid() === true ) {
                        e4.checkout.steps.direction = 'next';
                        e4.util.hash.set( 'step', jStep );
                    }
                } );
            },
            companyAddress: function () {
                jQuery( '.js-e-checkout' ).on( 'change', '.js-e-checkout-company-toggle', function ( event ) {
                    event.preventDefault();
                    jQuery( this ).closest( '.js-e-checkout-step-form' ).find( '.js-company-label-toggle' ).toggle();
                    jQuery( this ).closest( '.js-e-checkout-step-form' ).find( '.js-e-checkout-company-address-fieldset' ).slideToggle( e4.settings.animation.speed );

                    if ( jQuery( this ).val() === 'private' ) {
                        jQuery( this ).closest( '.js-e-checkout-step-form' ).find( '.js-company-control-value' ).val( '' );
                    }

                } );
            },
            methodChange: function ( step ) {
                if ( step === 'payment' || step === 'shipping' ) {
                    jQuery( '.js-e-checkout-step[data-step="' + step + '"]' ).removeClass( 'e-checkout-step-has-changed' );
                    e4.checkout.order.hasChanged[ step ] = false;
                    //TODO - open and scroll to first step with issue if current step is valid
                }
            },
            edit: function () {
                jQuery( '.js-e-checkout, .js-e-checkout-summery-modal' ).on( 'click', '.js-e-checkout-edit-step', function ( event ) {
                    event.preventDefault();
                    var jStep = jQuery( this ).data( 'target' ).replace( '#', '' );
                    e4.checkout.steps.target = jStep;
                    e4.checkout.steps.direction = 'previous';
                    e4.util.hash.set( 'step', jStep );
                } );
            }
        },
        customer: {
            init: function () {
                e4.checkout.steps.customer.bindEvents();

            },
            show: function () {
                if ( jQuery( '.js-e-checkout-has-alternative-billing-address:checked' ).val() === 'no' ) {
                    e4.checkout.steps.customer.mapDeliveryToBilling( jQuery( '.js-e-checkout-alternative-billing-address' ) );
                }
            },
            bindEvents: function () {
                jQuery( '.js-e-checkout' ).on( 'change', '.js-e-checkout-has-alternative-billing-address', function ( event ) {
                    var jAlternativeBillingAddress = jQuery( '.js-e-checkout-alternative-billing-address' );
                    if ( jQuery( this ).is( ':checked' ) === true && jQuery( this ).val() === 'yes' ) {
                        if ( event.isTrigger === undefined ) {
                            e4.checkout.steps.customer.clear();
                        }
                        jAlternativeBillingAddress.collapse( 'show' );
                        e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( '.js-e-checkout-alternative-billing-address' ) );

                    }
                    else {
                        jAlternativeBillingAddress.collapse( 'hide' );
                        e4.checkout.steps.customer.mapDeliveryToBilling( jAlternativeBillingAddress );
                    }
                } );
            },
            mapDeliveryToBilling: function ( jAlternativeBillingAddress ) {
                var mappingObj = {
                    EcomOrderCustomerName: jQuery( 'input[name="EcomOrderDeliveryName"]' ).val(),
                    EcomOrderCustomerCompany: jQuery( 'input[name="EcomOrderDeliveryCompany"]' ).val(),
                    EcomOrderCustomerAddress: jQuery( 'input[name="EcomOrderDeliveryAddress"]' ).val(),
                    EcomOrderCustomerAddress2: jQuery( 'input[name="EcomOrderDeliveryAddress2"]' ).val(),
                    EcomOrderCustmerZip: jQuery( 'input[name="EcomOrderDeliveryZip"]' ).val(),
                    EcomOrderCustomerCity: jQuery( 'input[name="EcomOrderDeliveryCity"]' ).val(),
                    EcomOrderCustomerPhone: jQuery( 'input[name="EcomOrderDeliveryPhone"]' ).val()
                };
                e4.checkout.steps.addressMapper( jAlternativeBillingAddress, mappingObj );
            },
            clear: function () {
                jQuery( '.js-e-checkout-alternative-billing-address' ).find( 'input[type="text"], input[type="number"]' ).val( '' );
                jQuery( '.js-e-checkout-alternative-billing-address' ).find( 'input[type="checkbox"]' ).prop( 'checked', false );
            }
        },
        payment: {
            init: function () {}
        },
        shipping: {
            selected: null,
            init: function () {
                e4.checkout.steps.shipping.bindEvents();
            },
            show: function () {
                e4.checkout.steps.shipping.gls.init();
                e4.checkout.steps.shipping.pacsoft.init();
            },
            mapAddressToForm: function(mappingItems){
                var mappingObj = {
                    EcomOrderDeliveryName: mappingItems.find('[data-item="EcomOrderDeliveryName"]').text(),
                    EcomOrderDeliveryCompany: mappingItems.find('[data-item="EcomOrderDeliveryCompany"]').text(),
                    EcomOrderDeliveryAddress: mappingItems.find('[data-item="EcomOrderDeliveryAddress"]').text(),
                    EcomOrderDeliveryAddress2: mappingItems.find('[data-item="EcomOrderDeliveryAddress2"]').text(),
                    EcomOrderDeliveryZip: mappingItems.find('[data-item="EcomOrderDeliveryZip"]').text(),
                    EcomOrderDeliveryCity: mappingItems.find('[data-item="EcomOrderDeliveryCity"]').text(),
                    EcomOrderDeliveryPhone: mappingItems.find('[data-item="EcomOrderDeliveryPhone"]').text()
                };
                e4.checkout.steps.addressMapper( jQuery('#AlternativeDeliveryAddress'), mappingObj );
            },
            clear: function () {
                var jDeliveryForm = jQuery( '.js-e-checkout-delivery-address' );
                jDeliveryForm.find( 'input[type="text"], input[type="number"]' ).val( '' );
                jDeliveryForm.find( 'input[type="checkbox"]' ).prop( 'checked', false );
                jDeliveryForm.find( '.js-e-checkout-toggle-target' ).hide();
            },
            bindEvents: function () {
                jQuery( '.js-e-checkout' ).on( 'change', '.js-e-checkout-shipping-method', function () {
                    var jShippingID = jQuery( this ).val(),
                        pacsoftShippingID = jQuery( '.js-e-checkout-shipping-id-pacsoft' ).val(),
                        glsShippingID = jQuery( '.js-e-checkout-shipping-id-gls' ).val();
                    if ( jShippingID === pacsoftShippingID ) {
                        e4.checkout.steps.shipping.pacsoft.show();
                        e4.checkout.steps.shipping.gls.hide();
                        e4.checkout.steps.shipping.standard.hide();
                        jQuery( '.js-e-checkout-shipping-type' ).val( 'pacsoft' );
                    }
                    else if ( jShippingID === glsShippingID ) {
                        e4.checkout.steps.shipping.gls.show();
                        e4.checkout.steps.shipping.pacsoft.hide();
                        e4.checkout.steps.shipping.standard.hide();
                        jQuery( '.js-e-checkout-shipping-type' ).val( 'gls' );
                    }
                    else {
                        e4.checkout.steps.shipping.pacsoft.hide();
                        e4.checkout.steps.shipping.gls.hide();
                        e4.checkout.steps.shipping.standard.show();
                        jQuery( '.js-e-checkout-shipping-type' ).val( 'standard' );
                    }
                } ).on( 'click', '.js-e-checkout-shippingmethod-pacsoft-lookup-btn', function () {
                    var jSearchValue = jQuery( '.js-e-checkout-shippingmethod-pacsoft-zip' ).val();
                    e4.checkout.steps.shipping.pacsoft.search( jSearchValue );
                } ).on( 'click', '.js-e-checkout-shippingmethod-gls-lookup-btn', function () {
                    var jSearchValue = jQuery( '.js-e-checkout-shippingmethod-gls-zip' ).val();
                    e4.checkout.steps.shipping.gls.search( jSearchValue );
                } ).on( 'click', '.js-e-checkout-shippingmethod-gls-result-item', function () {
                    var parcelShopID = jQuery( this ).val();
                    e4.checkout.steps.shipping.gls.set( parcelShopID );
                } ).on( 'click', '.js-e-checkout-shippingmethod-pacsoft-result-item', function () {
                    var servicePointID = jQuery( this ).val();
                    e4.checkout.steps.shipping.pacsoft.set( servicePointID );
                } ).on( 'click', '.js-e-checkout-set-delivery-address', function () {
                    var jValue = jQuery( this ).val(),
                        mappingItems = jQuery(this).closest('.js-e-checkout-shipping-user-address-item').find('.js-e-checkout-set-delivery-address-mapping-data');
                    if ( jValue === '999' ){
                        e4.checkout.steps.shipping.clear();
                        jQuery('.js-e-checkout-delivery-address').collapse('show');
                    } else{
                        jQuery('.js-e-checkout-delivery-address').collapse('hide');
                        e4.checkout.steps.shipping.mapAddressToForm(mappingItems);
                    }
                } );
            },
            standard: {
                init: function () {},
                show: function () {
                    jQuery( '.js-e-checkout-delivery-address' ).collapse( 'show' );
                },
                hide: function () {
                    jQuery( '.js-e-checkout-delivery-address' ).collapse( 'hide' );
                    e4.checkout.steps.shipping.clear();
                }
            },
            pacsoft: {
                init: function () {
                    e4.checkout.steps.shipping.pacsoft.bindEvents();
                    if (e4.checkout.steps.shipping.pacsoft.data){
                        jQuery( '#js-e-checkout-shippingmethod-pacsoft-results' ).html( e4.handlebars.partial.pacsoft( e4.checkout.steps.shipping.pacsoft.data ) );
                        jQuery('.js-e-checkout-shippingmethod-pacsoft-zip').data('rule-required', false);
                    }
                },
                show: function () {
                    jQuery( '.js-e-checkout-shippingmethod-pacsoft' ).collapse( 'show' );
                },
                hide: function () {
                    jQuery( '.js-e-checkout-shippingmethod-pacsoft' ).collapse( 'hide' );
                    e4.checkout.steps.shipping.pacsoft.clear();
                },
                clear: function () {
                    e4.checkout.steps.shipping.clear();
                    jQuery( '.js-e-checkout-shippingmethod-pacsoft-id' ).val( '' );
                    jQuery( '.js-e-checkout-shippingmethod-pacsoft-search-string' ).val( '' );
                    e4.checkout.steps.shipping.pacsoft.data = null;
                    jQuery('.js-e-checkout-shippingmethod-pacsoft-zip').data('rule-required', true);
                    jQuery( '#js-e-checkout-shippingmethod-pacsoft-results' ).slideUp( e4.settings.animation.speed, function () {
                        jQuery( this ).html( '' );
                    } );
                },
                bindEvents: function () {
                    jQuery( '.js-e-checkout' ).on( 'keydown blur', '.js-e-checkout-shippingmethod-pacsoft-zip', function ( event ) {
                        var jSearchValue = jQuery( this ).val();
                        jQuery( '.js-e-checkout-shippingmethod-pacsoft-search-string' ).val( jSearchValue + ' ' + jQuery( '.js-e-checkout-shippingmethod-pacsoft-address' ).val() );
                        if ( jSearchValue.length > 3 ) {
                            if ( event.which === 9 || event.which === 13 || event.type === 'focusout' ) {
                                e4.checkout.steps.shipping.pacsoft.search( jSearchValue );
                            }

                        }
                        /*
                        else if ( jSearchValue.length === 0 ) {
                            e4.checkout.steps.shipping.pacsoft.clear();
                        }
                        */
                    } ).on( 'keydown blur', '.js-e-checkout-shippingmethod-pacsoft-address', function ( event ) {
                        var jSearchValue = jQuery( this ).val();
                        jQuery( '.js-e-checkout-shippingmethod-pacsoft-search-string' ).val( jQuery( '.js-e-checkout-shippingmethod-pacsoft-zip' ).val() + ' ' + jSearchValue );
                        if ( jQuery( '.js-e-checkout-shippingmethod-pacsoft-zip' ).val() && jSearchValue.length > 3 ) {
                            if ( event.which === 9 || event.which === 13 || event.type === 'focusout' ) {
                                e4.checkout.steps.shipping.pacsoft.search( jQuery( '.js-e-checkout-shippingmethod-pacsoft-zip' ).val() );
                            }
                        }
                    } ).on( 'click', '.js-e-checkout-shippingmethod-pacsoft-edit-btn', function ( event ) {
                        event.preventDefault();
                        e4.checkout.steps.shipping.pacsoft.clear();
                        jQuery( window ).triggerHandler( 'hashchange' );

                    } );
                },
                set: function ( servicePointID ) {
                    var jAlternativeShippingAddress = jQuery( '.js-e-checkout-delivery-address' );
                    for ( var i = 0; i < e4.checkout.steps.shipping.pacsoft.data.servicePoints.length; i++ ) {
                        e4.checkout.steps.shipping.pacsoft.data.servicePoints[ i ].isSelected = '';
                        if ( e4.checkout.steps.shipping.pacsoft.data.servicePoints[ i ].servicePointId === servicePointID ) {
                            var mappingObj = {
                                EcomOrderDeliveryName: e4.checkout.steps.shipping.pacsoft.data.servicePoints[ i ].name,
                                EcomOrderDeliveryCompany: e4.checkout.steps.shipping.pacsoft.data.servicePoints[ i ].name,
                                EcomOrderDeliveryAddress: e4.checkout.steps.shipping.pacsoft.data.servicePoints[ i ].deliveryAddress.streetName + ' ' + e4.checkout.steps.shipping.pacsoft.data.servicePoints[ i ].deliveryAddress.streetNumber,
                                EcomOrderDeliveryZip: e4.checkout.steps.shipping.pacsoft.data.servicePoints[ i ].deliveryAddress.postalCode,
                                EcomOrderDeliveryCity: e4.checkout.steps.shipping.pacsoft.data.servicePoints[ i ].deliveryAddress.city
                            };
                            e4.checkout.steps.shipping.pacsoft.data.servicePoints[ i ].isSelected = true;
                            e4.checkout.steps.addressMapper( jAlternativeShippingAddress, mappingObj );
                            jQuery( '.js-e-checkout-shippingmethod-pacsoft-id' ).val( e4.checkout.steps.shipping.pacsoft.data.servicePoints[ i ].servicePointId );
                        }
                        jQuery('.js-e-checkout-shippingmethod-pacsoft-zip').data('rule-required', false);
                    }
                },
                search: function ( pacsoftZip ) {
                    var pacsoftAddress = jQuery( '.js-e-checkout-shippingmethod-pacsoft-address' ).val();
                    jQuery.ajax( {
                            url: '/pnlookup.ashx',
                            type: 'POST',
                            beforeSend: function () {
                                jQuery( '.js-e-checkout-step-loading-overlay' ).addClass( 'is-loading' );
                            },
                            data: {
                                countryCode: jQuery( '#EcomOrderDeliveryCountry' ).val(),
                                numberOfServicePoints: e4.settings.ecom.checkout.shipping.searchCount,
                                postalCode: pacsoftZip,
                                streetName: pacsoftAddress

                            }
                        } )
                        .done( function ( data ) {
                            if ( e4.checkout.debug === true ) {
                                // console.info( data );
                            }
                            e4.checkout.steps.shipping.pacsoft.data = data.servicePointInformationResponse;
                            jQuery( '.js-e-checkout-step-loading-overlay' ).removeClass( 'is-loading' );
                            e4.checkout.steps.shipping.pacsoft.build();
                        } );

                },
                build: function () {
                    jQuery( '#js-e-checkout-shippingmethod-pacsoft-results' ).html( e4.handlebars.partial.pacsoft( e4.checkout.steps.shipping.pacsoft.data ) ).slideDown( e4.settings.animation.speed, function () {
                        //jQuery( this ).find( '.js-e-checkout-shippingmethod-pacsoft-result-item' ).first().prop( 'checked', true ).trigger( 'click' );
                        e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( '.js-e-checkout-shippingmethod-pacsoft' ) );
                    } );

                }
            },
            gls: {
                init: function () {
                    e4.checkout.steps.shipping.gls.bindEvents();
                    if (e4.checkout.steps.shipping.gls.data){
                        jQuery( '#js-e-checkout-shippingmethod-gls-results' ).html( e4.handlebars.partial.gls( e4.checkout.steps.shipping.gls.data ) );
                        jQuery('.js-e-checkout-shippingmethod-gls-zip').data('rule-required', false);
                    }
                },
                show: function () {
                    jQuery( '.js-e-checkout-shippingmethod-gls' ).on( 'shown.bs.collapse', function () {
                        if ( jQuery( '.js-e-checkout-shippingmethod-gls-zip' ).val() ) {
                            jQuery( '.js-e-checkout-shippingmethod-gls-zip' ).trigger( 'keyup' );
                        }

                    } ).collapse( 'show' );
                },
                hide: function () {
                    jQuery( '.js-e-checkout-shippingmethod-gls' ).collapse( 'hide' );
                    e4.checkout.steps.shipping.gls.clear();
                },
                clear: function () {
                    e4.checkout.steps.shipping.clear();
                    jQuery( '.js-e-checkout-shippingmethod-gls-id' ).val( '' );
                    jQuery( '.js-e-checkout-shippingmethod-gls-search-string' ).val( '' );
                    e4.checkout.steps.shipping.gls.data = null;
                    jQuery('.js-e-checkout-shippingmethod-gls-zip').data('rule-required', true);
                    jQuery( '#js-e-checkout-shippingmethod-gls-results' ).slideUp( e4.settings.animation.speed, function () {
                        jQuery( this ).html( '' );
                    } );

                },
                bindEvents: function () {
                    jQuery( '.js-e-checkout' ).on( 'keydown blur', '.js-e-checkout-shippingmethod-gls-zip', function ( event ) {
                        var jSearchValue = jQuery( this ).val();
                        jQuery( '.js-e-checkout-shippingmethod-gls-search-string' ).val( jSearchValue + ' ' + jQuery( '.js-e-checkout-shippingmethod-gls-address' ).val() );
                        if ( jSearchValue.length > 3 ) {
                            if ( event.which === 9 || event.which === 13 || event.type === 'focusout' ) {
                                e4.checkout.steps.shipping.gls.search( jSearchValue );
                            }
                        }
                        /*
                        else if ( jSearchValue.length === 0 ) {
                            e4.checkout.steps.shipping.gls.clear();
                        }
                        */
                    } ).on( 'keydown blur', '.js-e-checkout-shippingmethod-gls-address', function ( event ) {
                        var jSearchValue = jQuery( this ).val();
                        jQuery( '.js-e-checkout-shippingmethod-gls-search-string' ).val( jQuery( '.js-e-checkout-shippingmethod-gls-zip' ).val() + ' ' + jSearchValue );
                        if ( jQuery( '.js-e-checkout-shippingmethod-gls-zip' ).val() && jSearchValue.length > 3 ) {
                            if ( event.which === 9 || event.which === 13 || event.type === 'focusout' ) {
                                e4.checkout.steps.shipping.gls.search( jQuery( '.js-e-checkout-shippingmethod-gls-zip' ).val() );
                            }
                        }
                    } ).on( 'click', '.js-e-checkout-shippingmethod-gls-edit-btn', function ( event ) {
                        event.preventDefault();
                        e4.checkout.steps.shipping.gls.clear();
                        jQuery( window ).triggerHandler( 'hashchange' );

                    } );

                },
                set: function ( parcelShopID ) {
                    var jAlternativeShippingAddress = jQuery( '.js-e-checkout-delivery-address' );
                    for ( var i = 0; i < e4.checkout.steps.shipping.gls.data.parcelshops.length; i++ ) {
                        e4.checkout.steps.shipping.gls.data.parcelshops[ i ].isSelected = '';
                        if ( e4.checkout.steps.shipping.gls.data.parcelshops[ i ].Number === parcelShopID ) {
                            var mappingObj = {
                                EcomOrderDeliveryName: e4.checkout.steps.shipping.gls.data.parcelshops[ i ].CompanyName,
                                EcomOrderDeliveryCompany: e4.checkout.steps.shipping.gls.data.parcelshops[ i ].Streetname2,
                                EcomOrderDeliveryAddress: e4.checkout.steps.shipping.gls.data.parcelshops[ i ].Streetname,
                                EcomOrderDeliveryZip: e4.checkout.steps.shipping.gls.data.parcelshops[ i ].ZipCode,
                                EcomOrderDeliveryCity: e4.checkout.steps.shipping.gls.data.parcelshops[ i ].CityName
                            };
                            e4.checkout.steps.shipping.gls.data.parcelshops[ i ].isSelected = true;
                            e4.checkout.steps.addressMapper( jAlternativeShippingAddress, mappingObj );
                            jQuery( '.js-e-checkout-shippingmethod-gls-id' ).val( e4.checkout.steps.shipping.gls.data.parcelshops[ i ].Number );
                        }
                        jQuery('.js-e-checkout-shippingmethod-gls-zip').data('rule-required', false);
                    }
                },
                search: function ( glsZip ) {
                    var glsAddress = jQuery( '.js-e-checkout-shippingmethod-gls-address' ).val();

                    jQuery.ajax( {
                            url: '/glslookup.ashx',
                            type: 'POST',
                            beforeSend: function () {
                                jQuery( '.js-e-checkout-step-loading-overlay' ).addClass( 'is-loading' );
                            },
                            data: {
                                count: e4.settings.ecom.checkout.shipping.searchCount,
                                type: glsAddress ? 'ADDRESS' : 'ZIP',
                                street: glsAddress,
                                zipcode: glsZip

                            }
                        } )
                        .done( function ( data ) {
                            if ( e4.checkout.debug === true ) {
                                // console.info( data );
                            }
                            e4.checkout.steps.shipping.gls.data = data;
                            jQuery( '.js-e-checkout-step-loading-overlay' ).removeClass( 'is-loading' );
                            e4.checkout.steps.shipping.gls.build();
                        } );

                },
                build: function () {
                    jQuery( '#js-e-checkout-shippingmethod-gls-results' ).html( e4.handlebars.partial.gls( e4.checkout.steps.shipping.gls.data ) ).slideDown( e4.settings.animation.speed, function () {
                        //jQuery( this ).find( '.js-e-checkout-shippingmethod-gls-result-item' ).first().prop( 'checked', true ).trigger( 'click' );
                        e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( '.js-e-checkout-shippingmethod-gls' ) );
                    } );

                }
            }

        },
        approve: {
            init: function () {
                e4.checkout.steps.approve.termsAndConditions.init();
            },
            show: function () {
                jQuery( '.js-e-checkout-approve-form' ).validate();
            },
            newsletterSubscription: function () {
                jQuery( '.js-e-checkout' ).on( 'change', 'input[name="Co3EspressoEcomOrderSubscribeToNewsletter"]', function () {
                    jQuery( '.js-e-checkout-approve-form' ).find( 'input[name="EcomOrderSubscribeToNewsletter"]' ).val( this.checked === true ? 'true' : '' );
                } ).triggerHandler( 'change' );
            },
            termsAndConditions: {
                init: function () {
                    e4.checkout.steps.approve.termsAndConditions.bindEvents();
                    e4.checkout.steps.approve.termsAndConditions.get();
                },
                bindEvents: function () {
                    jQuery( '.js-e-checkout' ).on( 'change', 'input[name="EcomOrderCustomerAccepted"]', function () {

                        jQuery( 'input[name="EcomOrderCustomerAccepted"]' ).prop( 'checked', jQuery( this ).prop( 'checked' ) );

                    } );
                    jQuery( '.js-e-accept-term-and-conditions' ).on( 'click', function () {
                        jQuery( 'input[name="EcomOrderCustomerAccepted"]' ).prop( 'checked', true );
                    } );
                },
                get: function () {
                    jQuery.get( e4.settings.ecom.checkout.termsAndConditions, function ( data ) {
                        var jHtml = jQuery( data ).find( '.e-content-main' ).html();
                        jQuery( '.js-e-checkout-modal-terms-and-conditions' ).replaceWith( jHtml );
                    } );
                }
            }
        },
        receipt: {
            init: function () {
                e4.util.hash.remove( 'step' );
                sessionStorage.removeItem( 'checkoutStep' );
                var step = {
                    index: 5,
                    name: 'receipt'
                };
                e4.checkout.tracking.step( step );
            }
        },
    },
    tracking: {
        step: function ( data ) {
            var virtualPageview = {
                'event': 'VirtualPageview',
                'vpevent': 'VirtualPageview',
                'virtualPageURL': '/shop/cart/checkout?step=' + data.index + ' ' + data.name,
                'virtualPageTitle': 'Order Step ' + data.index + ' – ' + data.name
            };
            var checkout = {
                'event': 'checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField': {
                            'step': data.index
                        }
                    }
                }
            };

            e4.gtm.pushDataLayer( virtualPageview );
            e4.gtm.pushDataLayer( checkout );
        }
    }
};

